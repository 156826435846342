import React, { PropsWithChildren, useState } from 'react';
import { Cotacao } from '../types/api-corsier';

const INITIAL_STATE: FormValues = {
  name: '',
  email: '',
  phone: '',
  renovacao: '',
  categoria: '',
  atividadesProcedimentos: [],
  residente: false,
  reclamacao: undefined,
  retroatividade: undefined,
  cobertura: 500000,
  cpf: '',
  dataNascimento: undefined,
  cep: '',
  endereco: '',
  numero: '',
  complemento: '',
  bairro: '',
  cidade: '',
  crm: '',
  expectativaSinistros: '',
  metodoPagamento: '',
  aceiteTermo: false,
  block: false,
  uf: '',
  cotacaoData: undefined,
  cotacaoDataComDesconto: undefined,
  simulacaoData: undefined,
  vencimento: 0,
  idPagamento: '',
  propostaData: [],
  numeroCartao: '',
  validade: '',
  nomeTitular: '',
  cvv: '',
  dataContratacaoRetroatividade: undefined,
  cienteTermoRetroatividade: false,
  apoliceFile: undefined,
  apoliceBase64: undefined,
  seguradoraAtual: '',
  possiveisReclamantes: '',
  cienteCoberturaSecuritaria: false,
  cupom: '',
  pep: '',
  nomePep: '',
  grauParentescoPep: '',
  medicoPerito: false,
  possuiNomeSocial: 'nao',
  nomeSocial: '',
  trafficSource: undefined,
}

const FormContext = React.createContext<FormValuesContext>({
  ...INITIAL_STATE,
  updateState: (state: Partial<FormValues>) => { },
});

export const FormContextProvider = (props: PropsWithChildren) => {
  const [state, setState] = useState<FormValues>(INITIAL_STATE);

  const updateState = (newState: Partial<FormValues>) => {
    setState((previous) => ({
      ...previous,
      ...newState,
    }));
  };

  return (
    <FormContext.Provider value={{ ...state, updateState }}>
      {props.children}
    </FormContext.Provider>
  );
}

export default FormContext;

interface FormValues {
  name: string;
  email: string;
  phone: string;
  renovacao: string;
  categoria: string;
  atividadesProcedimentos: string[];
  residente: boolean;
  reclamacao?: number;
  retroatividade?: number;
  cobertura: number;
  cpf: string;
  dataNascimento?: Date;
  cep: string;
  endereco: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  crm: string;
  expectativaSinistros: string;
  metodoPagamento: string;
  aceiteTermo: boolean;
  block: boolean;
  uf: string;
  cotacaoData?: Cotacao;
  cotacaoDataComDesconto?: Cotacao;
  simulacaoData?: Cotacao;
  vencimento: number;
  idPagamento: string;
  propostaData: Array<any>;
  numeroCartao: string;
  validade: string;
  nomeTitular: string;
  cvv: string;
  dataContratacaoRetroatividade?: Date;
  cienteTermoRetroatividade: boolean;
  apoliceFile?: string;
  apoliceBase64?: string;
  seguradoraAtual: string;
  possiveisReclamantes?: string;
  cienteCoberturaSecuritaria: boolean;
  cupom: string;
  pep: string;
  nomePep: string;
  grauParentescoPep: string;
  medicoPerito: boolean;
  possuiNomeSocial: string;
  nomeSocial?: string;
  trafficSource?: string;
}

interface FormValuesContext extends FormValues {
  updateState: (state: Partial<FormValues>) => void;
}
