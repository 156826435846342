import { Identificacao } from "./components/identificacao/Identificacao";
import { Especialidade } from "./components/especialidade/Especialidade";
import { Usuario } from "./components/usuario/Usuario";
import { Processo } from "./components/processo/Processo";
import { Retroatividade } from "./components/retroatividade/Retroatividade";
import { Simulacao } from "./components/simulacao/Simulacao";
import { Contrato } from "./components/contrato/Contrato";
import FormContext from "../../store/form-context";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import MultiStepsContext from "../../store/multi-steps-form-context";
import { ProgressBar } from "./components/progress-bar/ProgressBar";
import { Cpf } from "./components/cpf/Cpf";
import { RetroatividadeRenovacao } from "./components/retroatividade-renovacao/RetroatividadeRenovacao";
import { Pagamento } from "./components/pagamento/Pagamento";
import { Apolice } from "./components/apolice/Apolice";

const INITIAL_STEPS = [
  <Identificacao />,
  <Usuario />,
  <Especialidade />,
  <Processo />,
];

const MAIN_FLOW_STEPS = [
  <Retroatividade />,
  <Cpf />,
  <Simulacao />,
  <Contrato />,
  <Pagamento />,
];

const CIRURGIAO_FLOW_STEPS = [
  <Cpf />,
  <Simulacao />,
  <Contrato />,
  <Pagamento />,
];

const RENOVACAO_FLOW_STEPS = [
  <RetroatividadeRenovacao />,
  <Apolice />,
  <Cpf />,
  <Simulacao />,
];

export const Form = () => {
  const { categoria, renovacao, updateState } = useContext(FormContext);
  const [currentStep, setCurrentStep] = useState(0);

  const steps = useMemo(() => {
    const isCirurgiao = categoria === 'PLASTIC-SURGERY';
    const isRenovacao = renovacao === 'renovacao';
    let stepList;

    if (isRenovacao) {
      stepList = RENOVACAO_FLOW_STEPS;
    } else {
      stepList = isCirurgiao ? CIRURGIAO_FLOW_STEPS : MAIN_FLOW_STEPS;
    }

    return INITIAL_STEPS.concat(stepList);
  }, [categoria, renovacao]);

  const isFirstStep = currentStep === 0;
  const isLastStep = currentStep + 1 === steps.length;

  const component = useMemo(() => steps[currentStep], [steps, currentStep]);

  const goForward = useCallback(() => {
    if (isLastStep) return;

    setCurrentStep((previous) => ++previous);
    window.scroll(0, 0);
  }, [isLastStep]);

  const goBack = useCallback(() => {
    if (isFirstStep) return;

    setCurrentStep((previous) => --previous);
    window.scroll(0, 0);
  }, [isFirstStep]);

  const goToStep = useCallback((newStep: number) => {
    setCurrentStep(newStep);
  }, []);

  const contextValue = useMemo(() => {
    return {
      currentStep,
      isFirstStep,
      isLastStep,
      goForward,
      goBack,
      goToStep,
    }
  }, [
    currentStep,
    isFirstStep,
    isLastStep,
    goForward,
    goBack,
    goToStep,
  ]);

  useEffect(() => {
    const initializeTrafficSource = () => {
      const trafficSource = document.cookie.split("; ")
        .find((cookie) => cookie.startsWith("__trf.src"))
        ?.replace("__trf.src=", '');

      if (trafficSource) {
        updateState({ trafficSource })
      }
    };

    initializeTrafficSource()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MultiStepsContext.Provider value={contextValue}>
      {<ProgressBar step={currentStep} />}
      <form onSubmit={(event) => event.preventDefault()}>
        <div>
          {component}
        </div>
      </form>
    </MultiStepsContext.Provider>
  )
};