import { TextInput } from '../../../UI/text-input/TextInput';
import { RadioInput } from '../../../UI/radio-input/RadioInput';
import styles from './Usuario.module.css';
import sharedStyles from '../../../SharedStyles.module.css';
import { useCallback, useContext, useState } from 'react';
import FormContext from '../../../../store/form-context';
import MultiStepsContext from '../../../../store/multi-steps-form-context';
import { NavigationButtons } from '../navigation-buttons/NavigationButtons';
import { ErrorMessage } from '../../../error-message/ErrorMessage';
import useYupSchema from '../../../../hooks/use-yup-schema';
import Yup from '../../../../utils/yup';
import { EMAIL_REP_EXP, PHONE_REG_EXP } from '../../../../utils/constants';
import useHttp from '../../../../hooks/use-http';
import { isEmpty } from 'lodash';

const SEGURO_OPTIONS = [
  {
    id: "contratacao",
    label: <span>Não, é minha <span className={sharedStyles.emphasis}>primeira contratação</span></span>,
    name: "seguro",
    value: "contratacao",
  },
  {
    id: "renovacao",
    label: <span>Sim, é uma <span className={sharedStyles.emphasis}>renovação</span></span>,
    name: "seguro",
    value: "renovacao",
  },
];

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Por favor, informe o seu email")
    .matches(EMAIL_REP_EXP, "Por favor, informe um email válido"),
  phone: Yup.string()
    .required("Por favor, informe o seu telefone")
    .matches(PHONE_REG_EXP, 'Por favor, informe um telefone válido'),
  renovacao: Yup.string().required(),
});

const removeEmptyValues = (object: Object) => {
  const clone = { ...object };

  Object.keys(clone).forEach((key) => {
    if (isEmpty(clone[key as keyof typeof clone])) {
      delete clone[key as keyof typeof clone];
    }
  });

  return clone;
};

export const Usuario = () => {
  const { name, email, phone, renovacao, trafficSource, updateState } = useContext(FormContext);
  const { goForward, goBack } = useContext(MultiStepsContext);
  const [forwardPressed, setForwardPressed] = useState(false);
  const { sendRequest } = useHttp();

  const { isValid, errors } = useYupSchema(
    {
      email,
      phone,
      renovacao,
    },
    validationSchema,
  );

  const onChangeSeguroOptions = (newRenovacao: string) => {
    updateState({ renovacao: newRenovacao });
  };

  const sendRdStation = useCallback(() => {
    const formattedPhone = '+55 ' + phone.replace('(', '').replace(')', '');
    const temSeguro = renovacao === 'renovacao' ? 'Sim, é uma renovação' : 'Não, é minha primeira contratação';
    const payload = {
      conversion_identifier: 'Médico protegido',
      email,
      name,
      personal_phone: formattedPhone,
      cf_medico_protegido_atualmente_voce_tem_um_seguro_respon: temSeguro,
      traffic_source: trafficSource
    };

    const data = {
      event_type: 'CONVERSION',
      event_family: 'CDP',
      payload: removeEmptyValues(payload),
    };

    sendRequest({
      url: 'https://api.rd.services/platform/conversions?api_key=dzngQiXUsAvPcvxWWXQMKWHVfQcXloruuvIY',
      requestConfig: {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      },
    });
  }, [email, name, renovacao, phone, trafficSource, sendRequest]);

  const validate = useCallback(() => {
    setForwardPressed(true);

    if (!isValid) return;

    sendRdStation();
    goForward();
  }, [isValid, sendRdStation, goForward]);

  return (
    <div className={sharedStyles.container}>
      <div className={styles.content}>
        <div >
          <p className={sharedStyles.text}>
            Muito prazer <span className={sharedStyles.emphasis}>Dr(a). {name}</span>!
          </p>
          <p className={sharedStyles.text}>
            Para iniciar preciso do seu
            <span className={sharedStyles.emphasis}> e-mail</span> e
            <span className={sharedStyles.emphasis}> celular</span> por favor
          </p>
        </div>

        <div className={styles.inputContainer}>
          <div className={sharedStyles.marginTop}>
            <TextInput
              id="email"
              label="E-mail"
              type="email"
              placeholder="Digite seu e-mail"
              value={email}
              onChange={(value) => updateState({ email: value })}
            />
            <ErrorMessage message={errors.email} isVisible={forwardPressed && !!errors.email} />
          </div>
          <div className={sharedStyles.marginTop}>
            <TextInput
              id="celular"
              label="Celular"
              type="tel"
              placeholder="Digite o número do seu celular"
              value={phone}
              maskType="phone"
              onChange={(value) => updateState({ phone: value })}
            />
            <ErrorMessage message={errors.phone} isVisible={forwardPressed && !!errors.phone} />
          </div>
        </div>

        <div className={sharedStyles.marginTop}>
          <div className={sharedStyles.marginTop}>
            <p className={sharedStyles.text}>
              Atualmente, você tem um seguro <span className={sharedStyles.emphasis}>Responsabilidade Civil</span>?
            </p>
          </div>

          <div className={sharedStyles.marginTop}>
            {SEGURO_OPTIONS.map(({ id, label, name, value }) => (
              <RadioInput
                key={id}
                id={id}
                label={label}
                name={name}
                value={value}
                checked={renovacao === value}
                onChange={onChangeSeguroOptions}
              />
            ))}
            <ErrorMessage message={errors.renovacao} isVisible={forwardPressed && !!errors.renovacao} />
          </div>
        </div>
        <div className={sharedStyles.marginNavigationButtons}>
          <NavigationButtons onClickForward={validate} onClickBack={goBack} />
        </div>
      </div>
    </div>
  )
};